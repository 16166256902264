<template>
    <div id="RoamingIndoor">
        <div id="content" @click="onDocumentMouseDown">
            <div id="layer" v-if="!(speed == 100)">
                <div class="progressStrip">
                    <div class="progressImg">
                        <div :style="'width:' + speed + '%'">
                            <img src="@/assets/lou.png" alt="">
                        </div>
                    </div>
                    <div class="progressBar">
            <span class="white" :style="'width:' + speed + '%'">
            </span>
                    </div>
                    <div class="tips">Loading...{{speed + '%'}}</div>
                </div>
            </div>
            <div class="zoom" @click="fullScreenView">
                <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-menuziyuanldpi"></use>
                </svg>
            </div>
            <CoolDialog
                    :dialog-title="meterReadingInfo.location"
                    :dialog-visible="meterReadingVisible"
                    frame-width="180px"
                    @onClose="controlDirection"
                    :scale="scale"
                    dialogWidth="1200px"
            >
                <div slot="dialog-content" style="padding: 40px 30px;">
                    <div class="meterInfo">
                        <div class="meterInfo-left">
                            <div class="line">
                                <span>抄表时间：{{meterReadingInfo.time}}</span>
                            </div>
                            <div class="line">
                                <span>本次表底：{{meterReadingInfo.bottom}}</span>
                            </div>
                            <div class="line">
                                <span>表底留存：</span>
                            </div>
                            <img :src="'https://alioss.techops.cn/' + meterReadingInfo.readingPicUrl"/>
                        </div>
                        <div class="meterInfo-right">
                            <div class="line">
                                <span>上次表底：{{meterReadingInfo.lastBottom}}</span>
                            </div>
                            <div class="line">
                                <span>用量统计：{{meterReadingInfo.usageCount}}</span>
                            </div>
                            <div class="line">
                                <span>抄表人：{{meterReadingInfo.readingUser}}</span>
                            </div>
                            <img v-if="meterReadingInfo.userPhone"
                                 :src="'https://alioss.techops.cn/' + meterReadingInfo.userPhone"/>
                        </div>
                    </div>
                </div>
            </CoolDialog>
            <CoolDialog
                    :dialog-title="ibeaconInfo.buildingName + ibeaconInfo.floor + '层' + ibeaconInfo.specificLocation"
                    :dialog-visible="ibeaconVisible"
                    @onClose="controlDirection"
                    :scale="scale"
                    dialogWidth="736px"
                    frame-width="330px"
            >
                <div slot="dialog-content" class="dialog-content">
                    <div class="block" v-for="item in ibeaconInfo.datas" :key="item.id">
                        <div class="line">
                            <span>巡检时间：{{item.startTime + '-' + item.endTime.slice(11)}}</span>
                        </div>
                        <div class="line">
                            <span>签到时间：{{item.checkinTime}}</span>
                        </div>
                        <div class="line">
                            <div>检查项：</div>
                            <div class="check-item-list">
                                <div class="check-item" v-for="(checkItem, index) in item.items" :key="index">
                                    <img :src="transformation(checkItem)" v-if="transformation(checkItem)"/>
                                    <p class="record">{{checkItem.itemName}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="line">
                            <span>是否异常：{{item.timeOut ? "是" : "否"}}</span>
                        </div>
                        <div class="line">
                            <span>巡检人：{{item.planName}}</span>
                        </div>
                    </div>
                </div>
            </CoolDialog>
            <CoolDialog
                    dialog-width="720px"
                    dialog-title="一层大厅"
                    :dialogVisible="sensorVisible"
                    :scale="scale"
                    @onClose="controlDirection"
                    frame-width="200px"
            >
                <div slot="dialog-content" class="dialog-content" style="padding: 0 30px 0 30px">
                    <div class="sensor">
                        <img src="@/assets/chuanganqi.png" alt="">
                        <div class="sensor-info">
                            <span>楼内温度：23.7℃</span>
                            <span>楼内湿度：65%RH</span>
                            <span>楼内光照强度：1000lx</span>
                        </div>
                    </div>
                    <div class="sensor">
                        <img src="@/assets/chuanganqi.png" alt="">
                        <div class="sensor-info">
                            <span>PM2.5：44Ug/M³</span>
                            <span>CO₂：628PPM</span>
                            <span>甲醛：0.02PPM</span>
                        </div>
                    </div>
                </div>
            </CoolDialog>
            <CoolDialog
                    dialog-width="1340px"
                    :dialog-title="drawName"
                    :dialogVisible="gateVisible"
                    :scale="scale"
                    @onClose="controlDirection"
                    frame-width="430px"
            >
                <div slot="dialog-content" class="dialog-content">
                    <div class="current" v-for="item in gateInfo.result" :key="item.id">
                        <div>
                            <img :src="item.face" alt="" v-if="item.face !== 'data:'">
                            <img src="@/assets/wurenlian.png" alt="" v-else>
                        </div>
                        <div class="list">
                            <span>通行时间：{{item.time || '-' }}</span>
                            <span>姓名：{{item.userName ? item.userName.slice(0, 1).padEnd(3, '∗') : '陌生人' }}</span>
                            <span>手机号：{{item.phone || "-"}}</span>
                            <span>身份：{{item.type == 1 ? "楼内用户" : "访客"}}</span>
                            <span>身份证：{{item.idCard || "-"}}</span>
                            <span>通行方式：{{item.method == 1 ? "扫码" : "刷脸"}}</span>
                            <span>所属企业：{{item.company || "-"}}</span>
                            <span>所在房间：{{item.address || "-"}}</span>
                            <span>方向：{{item.way == 1 ? "进" : "出"}}</span>
                            <span>体温：{{item.bodyTemperature || "-"}}</span>
                        </div>
                    </div>
                </div>
            </CoolDialog>
        </div>
    </div>
</template>

<script>
    import * as Three from "three";
    import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js";
    import {Capsule} from 'three/examples/jsm/math/Capsule.js';
    import {Octree} from 'three/examples/jsm/math/Octree.js';
    import CoolDialog from "@/views/SmartPark/CoolDialog";
    import {
        queryMaterRecord,
        queryInspectionByCondition,
        queryPassLogByCondition,
        getFaceImgUrl,
        getUserFaceImage,
    } from "@/requestUrl";
    export default {
        name: "RoamingIndoor",
        components: {
            CoolDialog,
        },
        data() {
            return {
                rafId:null,
                // 摄像机
                camera: null,
                // 场景
                scene: null,
                // 渲染
                renderer: null,
                // 加载模型
                loader: null,
                // 加载模型进度
                speed: 0,
                clock: null,
                worldOctree: null,
                playerCollider: null,
                // 速度
                playerVelocity: null,
                // 方向
                playerDirection: null,
                keyStates: {},
                // 对话框标题(位置)
                locationTitle: '',
                // 抄表记录对话框显隐
                meterReadingVisible: false,
                // 抄表记录
                meterReadingInfo: {},
                // ibeacon数据
                ibeaconInfo: {},
                // ibeacon对话框显隐
                ibeaconVisible: false,
                // 传感器数据（目前这个模块还没有做）
                sensorInfo: {},
                // 传感器显隐
                sensorVisible: false,
                // 闸机信息
                gateInfo: {},
                // 闸机弹窗显隐
                gateVisible: false,
                // 切换有鼠标和无鼠标的模式
                pattern: false,
                // 缩放
                scale: '',
            }
        },
        mounted() {
            this.init();
            this.animate();
            this.judgeZoom();
            this.getMeterReading();
            this.getIbeacon();
            this.getGate();
            let timebar = setTimeout(() => {
                this.fullScreenView();
                clearTimeout(timebar);
            });
            //调整
            this.setScale();
        },
        computed: {
            drawName() {
                if (this.gateInfo && Object.keys(this.gateInfo).length > 0) {
                    return this.gateInfo.buildingName + this.gateInfo.floor + '层' + this.gateInfo.deviceName;
                }
                return '';
            }
        },
        beforeDestroy() {
            cancelAnimationFrame(this.rafId);
        },
        methods: {
            //递归查找节点
            selectNode(arr, name) {
                arr.forEach(item => {
                    if (item.name == 'name') {
                        return

                    } else {
                        this.worldOctree.fromGraphNode(item);
                    }
                    if (item.children) {
                        this.selectNode(item.children)
                    }
                })
            },
            init() {
                const content = document.getElementById('content');
                // 创建场景
                this.scene = new Three.Scene();
                // gltf/glb格式模型的加载机
                this.loader = new GLTFLoader();

                this.clock = new Three.Clock();

                this.playerVelocity = new Three.Vector3();
                this.playerDirection = new Three.Vector3();

                this.playerCollider = new Capsule(new Three.Vector3(-9.99, 0.01, 5), new Three.Vector3(-9.99, 1.8, 5), 0.5);

                this.worldOctree = new Octree();

                // 加载模型
                this.loader.load('https://cosmoss-static.oss-cn-shanghai.aliyuncs.com/triangle/3building1uncollision.glb', (gltf) => {
                    gltf.scene.scale.set(1, 1, 1);
                    this.scene.add(gltf.scene);
                    gltf.scene.position.set(0, 0, 0); //点光源位置
                    gltf.scene.traverse(child => {
                    });
                    this.loader.load('https://cosmoss-static.oss-cn-shanghai.aliyuncs.com/triangle/3building1collision.glb',
                        (gltfs) => {
                            gltfs.scene.scale.set(1, 1, 1);
                            gltfs.scene.position.set(0, 0, 0); //点光源位置
                            this.scene.add(gltfs.scene);
                            this.worldOctree.fromGraphNode(gltfs.scene);
                            gltfs.scene.traverse((child) => {
                            });
                        }
                    );

                }, (pro) => {
                    if (pro.loaded / pro.total == 1) {
                        this.speed == 99.99;
                        setTimeout(() => {
                            this.speed = 100;
                        }, 2000)
                        return;
                    }
                    this.speed = (pro.loaded / pro.total * 100).toFixed(2);
                });

                // 添加环境光
                const ambient = new Three.AmbientLight(0xffffff);
                this.scene.add(ambient);

                // 添加摄像机
                this.camera = new Three.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
                this.camera.rotation.y = 0;
                //this.camera.position.set(-20.901193543855538, 2.48999999910884,  13.191996624994376);
                // 千万不要动！不然y方向就反了！
                this.camera.rotation.order = 'YXZ';

                // 创建渲染器
                this.renderer = new Three.WebGL1Renderer();
                this.renderer.setSize(window.innerWidth, window.innerHeight);

                // 插入canvas对象
                document.getElementById('content').appendChild(this.renderer.domElement);

                // 漫游
                document.getElementById('content').addEventListener('mousemove', this.setRotation);
                this.camera.aspect = document.getElementById('content').innerWidth / document.getElementById('content').innerHeight;
                document.addEventListener('keydown', (event) => {
                    this.keyStates[event.code] = true;
                })
                document.addEventListener('keyup', (event) => {
                    this.keyStates[event.code] = false;
                })
                document.getElementById('content').addEventListener('mousedown', this.mouseClick);
            },
            // 画面移动
            setRotation(event) {
                this.camera.rotation.y -= event.movementX / 500;
                this.camera.rotation.x -= event.movementY / 500;
            },
            // 获取鼠标点击的位置
            onDocumentMouseDown(event) {
                event.preventDefault();
                let vector = new Three.Vector3();
                vector.set(((event.clientX - document.getElementById('content').offsetLeft) / document.getElementById('content').clientWidth) * 2 - 1, -((event.clientY - document.getElementById('content').offsetTop) / document.getElementById('content').clientHeight) * 2 + 1, 0.5);
                vector.unproject(this.camera);
                let raycaster = new Three.Raycaster(this.camera.position, vector.sub(this.camera.position).normalize());
                let intersects = raycaster.intersectObjects(this.scene.children, true);
                if (intersects.length > 0) {
                    let selected = intersects[0];
                    // 获取第一个物体名字
                    let objName = selected.object.name;
                    console.log(objName, 5555555)
                    switch (objName) {
                        case 'Object_0001_2':
                        case 'Object_0_2':
                            cancelAnimationFrame(this.rafId);
                            this.$router.push({
                                name: 'InsideBuilding7'
                            })

                            break
                        case 'dianbiao_1':
                            this.getMeterReading();
                            this.meterReadingVisible = true;
                            document.getElementById('content').removeEventListener('mousemove', this.setRotation);
                            break;
                        case 'ibeacon_2':
                            this.getIbeacon();
                            this.ibeaconVisible = true;
                            document.getElementById('content').removeEventListener('mousemove', this.setRotation);
                            break;
                        case 'chuanganqi_2':
                            this.sensorVisible = true;
                            document.getElementById('content').removeEventListener('mousemove', this.setRotation);
                            break;
                        case '1层入口门':
                        case 'men':
                            this.scene.clear();
                            if (document.exitFullScreen) {
                                document.exitFullScreen();
                            } else if (document.mozCancelFullScreen) {
                                document.mozCancelFullScreen();
                            } else if (document.webkitExitFullscreen) {
                                document.webkitExitFullscreen();
                            } else if (element.msExitFullscreen) {
                                element.msExitFullscreen();
                            }
                            this.clearScene();
                            this.$router.push({path: "SmartPark?name=RoamingIndoor"})
                            // this.$router.go(-1);
                            break;
                        case 'zhaji':
                            this.getGate();
                            this.gateVisible = true;
                            document.getElementById('content').removeEventListener('mousemove', this.setRotation);
                            break;
                        case 'zhaji2':
                            this.getGate();
                            this.gateVisible = true;
                            document.getElementById('content').removeEventListener('mousemove', this.setRotation);
                            break;
                        case 'zhajimen_2':
                            this.getGate();
                            this.gateVisible = true;
                            document.getElementById('content').removeEventListener('mousemove', this.setRotation);
                            break;
                        case 'zhajimen2_2':
                            this.getGate();
                            this.gateVisible = true;
                            document.getElementById('content').removeEventListener('mousemove', this.setRotation);
                            break;
                        default:
                            break;
                    }
                }
            },
            //释放内存
            clearScene() {
                cancelAnimationFrame(this.rafId);
                this.scene.traverse((child) => {
                    if (child.material) {
                        child.material.dispose();
                    }
                    if (child.geometry) {
                        child.geometry.dispose();
                    }
                    child = null;
                });

                // 场景中的参数释放清理或者置空等
                // this.sceneDomElement.innerHTML = '';
                // this.renderer.forceContextLoss();
                // this.renderer.dispose();
                 this.scene.clear();
                // this.flows = [];
                this.scene = null;
                // this.camera = null;
                // this.controls = null;
                // this.renderer.domElement = null;
                // this.renderer = null;
                // this.sceneDomElement = null;
                // console.log('clearScene');
            },
            // 弹窗关闭, 添加漫游鼠标控制方向
            controlDirection() {
                this.meterReadingVisible = false;
                this.ibeaconVisible = false;
                this.sensorVisible = false;
                this.gateVisible = false;
                document.getElementById('content').addEventListener('mousemove', this.setRotation);
            },
            getForwardVector() {
                this.camera.getWorldDirection(this.playerDirection);
                this.playerDirection.y = 0;
                this.playerDirection.normalize();
                return this.playerDirection;
            },
            getSideVector() {
                this.camera.getWorldDirection(this.playerDirection);
                this.playerDirection.y = 0;
                this.playerDirection.normalize();
                this.playerDirection.cross(this.camera.up);
                return this.playerDirection;
            },
            // 触发按键事件
            moveControls(deltaTime) {
                console.log('监听园区C');
                // 移动速度
                const speed = 30;
                if (this.keyStates['KeyW']) {
                    this.playerVelocity.add(this.getForwardVector().multiplyScalar(speed * deltaTime));
                    // console.log( this.camera.position.z)
                }
                if (this.keyStates['KeyS']) {
                    this.playerVelocity.add(this.getForwardVector().multiplyScalar(-speed * deltaTime));
                }
                if (this.keyStates['KeyA']) {
                    this.playerVelocity.add(this.getSideVector().multiplyScalar(-speed * deltaTime));
                }
                if (this.keyStates['KeyD']) {
                    this.playerVelocity.add(this.getSideVector().multiplyScalar(speed * deltaTime));
                }

                if (this.keyStates['Tab']) {
                    document.getElementById('content').addEventListener('mousedown', this.mouseClick);
                }
            },
            // 将鼠标指针锁定在指定元素上
            mouseClick() {
                document.getElementById('content').requestPointerLock();
                if (this.pattern) {
                    document.getElementById('content').removeEventListener('mousedown', this.mouseClick);
                    document.exitPointerLock();
                    this.pattern = false;
                } else {
                    this.pattern = true;
                }
            },
            // 碰撞检测
            playerCollitions() {
                const result = this.worldOctree.capsuleIntersect(this.playerCollider);
                if (result) {
                    // if((this.camera.position.x >=-21.5 && this.camera.position.x <= -19&&this.camera.position.z <=12&&this.camera.position.z >=1)){
                    //     return
                    // }
                    this.playerVelocity.addScaledVector(result.normal, -result.normal.dot(this.playerVelocity));
                    this.playerCollider.translate(result.normal.multiplyScalar(result.depth));
                }
            },
            updatePlayer(deltaTime) {
                const damping = Math.exp(-3 * deltaTime) - 1;
                this.playerVelocity.addScaledVector(this.playerVelocity, damping);
                const deltaPosition = this.playerVelocity.clone().multiplyScalar(deltaTime);
                this.playerCollider.translate(deltaPosition);
                this.playerCollitions();
                this.playerCollider.end.y = 1.2;
                this.camera.position.copy(this.playerCollider.end);
            },
            animate() {
                const deltaTime = Math.min(0.05, this.clock.getDelta()) / 5;
                this.moveControls(deltaTime);
                this.updatePlayer(deltaTime);


                this.renderer.render(this.scene, this.camera);
                //this.setScale();


                cancelAnimationFrame(this.rafId);
                this.rafId = requestAnimationFrame(this.animate);
            },
            // 调整缩放倍数
            setScale() {
                let clWidth = document.body.clientWidth;
                if (clWidth < 1366) {
                    this.scale = 'scale(0.5)';
                } else if (clWidth < 1600) {
                    this.scale = 'scale(0.6)';
                } else if (clWidth < 2000) {
                    this.scale = 'scale(0.8)';
                } else {
                    this.scale = '';
                }
            },
            // 判断全屏
            judgeZoom() {
                document.addEventListener('fullscreenchange', function (e) {
                    if (document.fullscreenElement) {
                        document.getElementsByClassName("zoom")[0].style.opacity = 0;
                    } else {
                        document.getElementsByClassName("zoom")[0].style.opacity = 1;
                    }
                });
            },
            // 获取抄表记录
            async getMeterReading() {
                let params = {
                    id: -1,
                    name: 'RQBKJYQ7001',
                    regionCode: this.$vc.getCurrentStaffInfo().regionCode,
                }
                let res = await this.$fly.post(queryMaterRecord, params);
                if (res.code != 0) {
                    return
                }
                this.meterReadingInfo = res.data;
            },
            // 获取ibeacon记录
            async getIbeacon() {
                let params = {
                    id: -1,
                    regionCode: this.$vc.getCurrentStaffInfo().regionCode,
                    beaconId: "1379b9d1-54d2-46aa-9490-de9fcbcccb99"
                }
                let res = await this.$fly.post(queryInspectionByCondition, params);
                if (res.code != 0) {
                    return;
                }
                this.ibeaconInfo = res.data;
            },
            // 字符串转换为对象
            transformation(str) {
                if (str.itemPhone) {
                    return JSON.parse(str.itemPhone)[0];
                }
            },
            // 获取闸机数据
            async getGate() {
                let params = {
                    id: -1,
                    deviceId: 49,
                    regionCode: this.$vc.getCurrentStaffInfo().regionCode,
                }
                let res = await this.$fly.post(queryPassLogByCondition, params);
                if (res.code != 0) {
                    return;
                }
                for (let i = 0; i < res.data.result.length; i++) {
                    const item = res.data.result[i];
                    let url = item.identity ? getUserFaceImage : getFaceImgUrl;
                    item.face = await this.$fly.readFileStream({
                        url,
                        data: {
                            userId: item.userId
                        }
                    })
                }
                this.gateInfo = res.data;
            },
            // 全屏
            fullScreenView() {
                const content = document.getElementById('content');
                if (content.requestFullscreen) {
                    content.requestFullscreen();
                } else if (content.mozRequestFullScreen) {
                    content.mozRequestFullScreen();
                } else if (content.webkitRequestFullscreen) {
                    content.webkitRequestFullscreen();
                } else if (content.msRequestFullscreen) {
                    content.msRequestFullscreen();
                }
            },
        },
    }
</script>

<style lang="stylus">
    #content
        width 100%
        height 100%
        position relative

        canvas
            width 100% !important
            height 100% !important

    #layer
        height 100%
        width 100%
        background-color rgba(0, 0, 0, 0.5)
        position fixed
        top 0
        left 0

    .zoom
        width 30px
        height 30px
        position absolute
        right 10px
        top 0
        font-size 30px

    .progressStrip
        width 520px
        height 70px
        position absolute
        top 50%
        left 50%
        border-radius 16px
        transform translate(-50%, -50%)

        .progressImg
            width 485px
            height 100px
            margin-left 17px

            div
                height 100px
                overflow hidden

                img
                    width 485px
                    height 100px

        .progressBar
            width 520px
            height 70px
            background #999
            border 5px solid #fff
            border-radius 14px

            .white
                height 100%
                background-color #fff
                display inline-block
                border-radius 6px

        .tips
            color #fff
            font-size 44px
            text-align center

    .dialog-content
        padding 20px 40px 0 40px

        .block
            border-bottom 1px solid #f0f0f0
            color #fff

            .line
                margin-bottom 30px
                font-size 38px

                img
                    width 120px
                    height 150px

                .check-item-list
                    display flex

                    .check-item
                        margin-right 50px
                        text-align center

                        &:not(:last-of-type)
                            margin-right 50px

                        img
                            width 160px
                            height 200px

                        p
                            font-size 24px
                            margin-bottom 0

        .sensor
            padding 30px 0 30px 0
            display flex
            color #fff

            &:not(:last-of-type)
                border-bottom 1px solid #f0f0f0

            img
                width 200px
                height 200px
                margin-right 30px

            .sensor-info
                font-size 38px
                display flex
                flex-flow column
                justify-content space-evenly

        .current
            display flex
            padding 20px 0 40px 0
            color #fff

            &:not(:last-of-type)
                border-bottom 1px solid #f0f0f0
                padding-bottom 20px

            img
                width 80px
                height 110px
                margin-right 30px

            .list
                display flex
                flex-wrap wrap
                flex-flow cloumn

                span
                    margin-right 10px
                    font-size 18px
                    width 276px
                    overflow hidden
                    text-overflow ellipsis
                    white-space nowrap

    .meterInfo
        display flex
        font-size 38px
        color #fff

        .meterInfo-left
            margin-right 80px

            .line
                margin-bottom 20px

            img
                width 160px
                height 200px

        .meterInfo-right
            .line
                margin-bottom 20px

            img
                width 160px
                height 200px
</style>
